import React, { useEffect, useState } from 'react'

import {Button,Menu,Typography,Avatar} from 'antd'
import {Link} from "react-router-dom"
import { HomeOutlined,MoneyCollectOutlined,BulbOutlined,FundOutlined,MenuOutlined } from '@ant-design/icons'
import icon from "../images/tarek.PNG"

 const Navbar = () => {

    const [activeMenu, setactiveMenu] = useState(true);
    // const [screenSize, setscreenSize] = useState(null);

    // useEffect(() => {
    //     const handleResize = ()=> setscreenSize(window.innerWidth);
    //     window.addEventListener('resize',handleResize);
    //     handleResize();
    // }, []);

    // useEffect(() => {
    //     if(screenSize <700){
    //         setactiveMenu(false);
    //     }  else if (screenSize>700) {
    //         setactiveMenu(true);
    //     }
    // }, []);
  return (
      <div className="nav-container">
          <div className="logo-container">
              <Avatar src={icon} size='large' />
              <Typography.Title level={2} className='logo'>
                  <Link to='/'>Cryptoverse</Link>
              </Typography.Title>
              <Button className="menu-control-container" onClick={()=>setactiveMenu(!activeMenu)}>
                <MenuOutlined />
                  
              </Button>
          </div>
          {activeMenu && (
            <Menu theme='dark'>
              <Menu.Item icon={<HomeOutlined/>}>
                  <Link to='/'>Home</Link>
              </Menu.Item>
              <Menu.Item icon={<FundOutlined/>}>
                  <Link to='/cryptocurrencies'>Cryptocurrencies</Link>
              </Menu.Item>
             
              <Menu.Item icon={<BulbOutlined/>}>
                  <Link to='/news'>News</Link>
              </Menu.Item>
          </Menu>
          )}
         
      </div>
  )
}

export default Navbar;